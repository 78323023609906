export default defineNuxtRouteMiddleware((to, from) => {
  const { isLoggedIn } = storeToRefs(useAuthStore())
  if (!isLoggedIn.value) {
    const { href } = useRequestURL()
    logger.debug('Redirect to login page', {
      href,
      to,
      from,
    })
    /**
     * Black list hrefs to ignore redirect to after login
     * If value is string, it will compare with route name
     * If value is RegExp, it will test with current href
     * @type {Array<string | RegExp>}
     */
    const blackListHrefs: Array<string | RegExp> = ['index', 'logout']
    const isIgnore = blackListHrefs.some((item) => {
      if (typeof item === 'string') {
        return item === to.name
      }

      if (item instanceof RegExp) {
        return item.test(href)
      }

      return false
    })

    const query: Record<string, string> = {}
    if (!isIgnore) {
      query.redirect = href
    }

    return navigateTo({
      name: 'login',
      query,
    })
  }
})
